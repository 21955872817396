import Link from "next/link";

export default function Footer() {
	const currentYear = new Date().getFullYear();

	return (
		<div id="footer" className="text-2xs mb-12 mt-48">
			{/* {ENVIRONMENT !== "production" && (
				<div className="my-4">
					<DevLinks />
				</div>
			)} */}
			<div className="my-4 mb-4">
				<Link href="/">
					<a className="mr-4 fancy-underline inline-flex">Home</a>
				</Link>
				{/* <Link href="/mint">
					<a className="mr-4 fancy-underline inline-flex">Mint</a>
				</Link> */}
				<Link href="/wallet">
					<a className="mr-4 fancy-underline inline-flex">Wallet</a>
				</Link>
				<Link href="/roadmap">
					<a className="mr-4 fancy-underline inline-flex">Roadmap</a>
				</Link>
			</div>
			<div className="flex flex-row mb-2">
				<a className="fancy-underline inline-flex mr-2" href="https://luciensmithstudio.com" rel="noreferrer" target="_blank">
					Lucien Smith
				</a>{" "}
				-{" "}
				<a className="fancy-underline inline-flex ml-2" href="https://stp.world/" rel="noreferrer" target="_blank">
					Serving the People
				</a>
			</div>
			<div className="flex mb-4">
				<a className="fancy-underline inline-flex" href="https://lobus.io" rel="noreferrer" target="_blank">
					Lobus
				</a>
				<span className="graphik">:</span>
				<a className="fancy-underline inline-flex ml-4" href="https://www.linkedin.com/company/lobus/about/" rel="noreferrer" target="_blank">
					LinkedIn
				</a>
				<a className="fancy-underline inline-flex ml-4" href="https://www.instagram.com/lobus__/" rel="noreferrer" target="_blank">
					Instagram
				</a>
				<a className="fancy-underline inline-flex ml-4" href="https://twitter.com/lobus__" rel="noreferrer" target="_blank">
					Twitter
				</a>
			</div>
			<div>
				<p>
					Copyright {currentYear} <a href="https://lobus.io">Lobus</a>, Inc. All Rights Reserved
				</p>
				<a href="/privacy" rel="noreferrer" target="_blank">
					Privacy Policy
				</a>
				,{" "}
				<a href="/tos" rel="noreferrer" target="_blank">
					Terms of Service
				</a>
				,{" "}
				<a href="/license" rel="noreferrer" target="_blank">
					License
				</a>
			</div>
		</div>
	);
}
