import { useEffect, useState } from "react";
import Head from "next/head";
import Script from "next/script";
import { useRouter } from "next/router";

import useWeb3 from "../hooks/useWeb3";
import useMixpanel from "../hooks/useMixpanel";

import Footer from "./Footer";
import Header from "./Header";

import { ENVIRONMENT, NETWORK_ID as networkId, CONTRACT_ADDRESS, RPC_URL, PIXEL_ID } from "../constants";

const knownNetworks = {
	1: "ETH Mainnet",
	3: "Ropsten",
	4: "Rinkeby",
};

function useEnvironmentDisplayToggle() {
	const [open, setOpen] = useState(false);

	return { open, setOpen };
}

function EnvironmentDisplay() {
	const { network } = useWeb3();
	const { open, setOpen } = useEnvironmentDisplayToggle();
	const conditionalStyles = `${open ? " text-md" : " text-xs"}${ENVIRONMENT === "production" ? " hidden" : ""}`;
	const walletNetwork = network === undefined ? "No Wallet" : knownNetworks[network] || `Unrecognized ID (${network})`;

	return (
		<div
			className={`graphik max-w-full fixed p-4 bg-red-500 text-white text-center bottom-6 md:right-6 z-20 right-0${conditionalStyles}`}
			onClick={() => {
				setOpen(!open);
			}}
		>
			{!open && (
				<span className="font-bold">
					{ENVIRONMENT} | {knownNetworks[networkId]} | {walletNetwork}
				</span>
			)}
			{open && (
				<>
					<div>
						Environment: <span className="font-bold break-all">{ENVIRONMENT}</span>
					</div>
					<div>
						Configured Network ID: <span className="font-bold break-all">{knownNetworks[networkId] || `Unrecognized ID (${network})`}</span>
					</div>
					<div>
						Wallet Network ID: <span className="font-bold break-all">{knownNetworks[network] || `Unrecognized ID (${network})`}</span>
					</div>
					<div>
						Contract Address: <span className="font-bold break-all">{CONTRACT_ADDRESS}</span>
					</div>
					<div>
						RPC URL: <span className="font-bold break-all">{RPC_URL}</span>
					</div>
				</>
			)}
		</div>
	);
}

function useTrackPage() {
	const router = useRouter();
	const mixpanel = useMixpanel();

	useEffect(() => {
		if (mixpanel) {
			mixpanel.track(`Page Visit: ${router.pathname}`);
		}
	}, [router.pathname, mixpanel]);

	useEffect(() => {
		import("react-facebook-pixel")
			.then((x) => {
				return x.default;
			})
			.then((ReactPixel) => {
				const options = {
					autoConfig: true,
					debug: ENVIRONMENT !== "production", // enable logs
				};

				ReactPixel.init(PIXEL_ID, null, options); // facebookPixelId
				ReactPixel.pageView();

				router.events.on("routeChangeComplete", () => {
					ReactPixel.pageView();
				});
			});
	}, [router.events]);
}

export default function Layout({ children }) {
	useTrackPage();

	return (
		<>
			<Head>
				<title key="title">Lucien Smith - Seeds</title>
				<meta property="og:title" content="Lobus" />
				<meta
					property="og:description"
					content="SEEDS is a limited NFT collection comprised of 10,000 unique artworks inspired by the seed packets"
				/>
				<meta property="og:image" content="https://storage.googleapis.com/seeds-artificats/seeds-social-card.png" />
				<meta property="og:image:alt" content="SEEDS is a limited NFT collection comprised of 10,000 unique artworks inspired by the seed packets" />
				<meta property="og:locale" content="en_US" />
				<meta property="og:type" content="website" />
				<meta property="og:url" content="https://seeds.lobus.io" />
				<link rel="apple-touch-icon" sizes="57x57" href="icons/favicon-57.png" />
				<link rel="apple-touch-icon" sizes="60x60" href="icons/favicon-60.png" />
				<link rel="apple-touch-icon" sizes="72x72" href="icons/favicon-72.png" />
				<link rel="apple-touch-icon" sizes="76x76" href="icons/favicon-76.png" />
				<link rel="apple-touch-icon" sizes="114x114" href="icons/favicon-114.png" />
				<link rel="apple-touch-icon" sizes="120x120" href="icons/favicon-120.png" />
				<link rel="apple-touch-icon" sizes="144x144" href="icons/favicon-144.png" />
				<link rel="apple-touch-icon" sizes="152x152" href="icons/favicon-152.png" />
				<link rel="apple-touch-icon" sizes="180x180" href="icons/favicon-180.png" />
				<link rel="icon" type="image/png" href="icons/favicon-32.png" sizes="32x32" />
				<link rel="icon" type="image/png" href="icons/favicon-192.png" sizes="192x192" />
				<link rel="icon" type="image/png" href="icons/favicon-96.png" sizes="96x96" />
				<link rel="icon" type="image/png" href="icons/favicon-16.png" sizes="16x16" />
				<meta name="facebook-domain-verification" content="hg5nyqbivqt83dhp8rjz3jtw2w6dxr" />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:url" content="https://seeds.lobus.io" />
				<meta name="twitter:image" content="https://storage.googleapis.com/seeds-artificats/seeds-social-card.png" />
			</Head>
			<Script src="https://www.googletagmanager.com/gtag/js?id=GA_MEASUREMENT_ID" strategy="afterInteractive" />
			<Script id="google-analytics" strategy="afterInteractive">
				{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-EY05E1XQLX');
        `}
			</Script>
			<div className="min-h-screen sm:max-w-[345px] max-w-[428px] sm:px-0 mx-auto flex flex-col text-primary w-full">
				<Header />
				<div className="h-12" />
				<EnvironmentDisplay />
				<main className="flex flex-grow justify-center max-w-[345px] mx-auto mt-6 px-2 sm:px-0">{children}</main>
				<div className="flex" />
				<div className="w-[345px] max-w-full mx-auto">
					<Footer />
				</div>
			</div>
		</>
	);
}
