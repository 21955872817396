import { useContext, useState, useEffect } from "react";

import { Context as OnboardContext } from "../contexts/Onboard";
import useMixpanel from "./useMixpanel";

export default function useWeb3() {
	const [account, setAccount] = useState("");
	const { provider, signer, address, balance, network, ens, wallet, onboard } = useContext(OnboardContext);
	const mixpanel = useMixpanel();

	useEffect(() => {
		setAccount(address || "");
	}, [address]);

	async function connect() {
		try {
			if (mixpanel) {
				mixpanel.track("Attempting to connect to wallet");
			}
			await onboard.walletSelect();
		} catch (error) {
			if (mixpanel) {
				mixpanel.track("Failed to connect to wallet", { error: error.message });
			}
		}
	}

	function disconnect() {
		onboard.walletReset();
		mixpanel.track("Disconnected wallet");
	}

	return {
		provider,
		signer,
		account,
		balance,
		ens,
		network,
		onboard,
		wallet,
		connect,
		disconnect,
	};
}
