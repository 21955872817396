import { createContext, useState, useEffect } from "react";
import { ethers } from "ethers";
import Onboard from "bnc-onboard";
import type { ReactNode } from "react";
import type { JsonRpcSigner, Web3Provider } from "@ethersproject/providers";
import type { Ens, Wallet, API as OnboardAPI } from "bnc-onboard/dist/src/interfaces";

import useDeviceInfo from "../hooks/useDeviceInfo";
import useMixpanel from "../hooks/useMixpanel";

import { NETWORK_ID as networkId, RPC_URL as rpcUrl, DAPP_ID as dappId, APP_URL as appUrl, EMAIL as email, APP_NAME as appName } from "../constants";

function initOnboard(subscriptions, { isMobile = false } = {}) {
	return Onboard({
		dappId,
		hideBranding: true,
		networkId,
		subscriptions,
		walletCheck: [
			{ checkName: "derivationPath" },
			{ checkName: "connect" },
			{ checkName: "accounts" },
			{ checkName: "network" },
			// { checkName: "balance", minimumBalance: "100000" },
		],
		walletSelect: {
			wallets: [
				{ walletName: "metamask", preferred: !isMobile },
				{ walletName: "coinbase", preferred: true },
				{ walletName: "trezor", appUrl, email, rpcUrl, preferred: !isMobile },
				{ walletName: "ledger", rpcUrl, preferred: !isMobile },
				{
					walletName: "walletConnect",
					rpc: { [networkId]: rpcUrl },
					preferred: true,
				},
				{ walletName: "trust", rpcUrl },
				{ walletName: "cobovault", appName, rpcUrl },
				{ walletName: "keystone", appName, rpcUrl },
				{ walletName: "keepkey", rpcUrl },
				{ walletName: "lattice", appName, rpcUrl },
				{ walletName: "status" },
				{ walletName: "walletLink", rpcUrl },
				{ walletName: "torus" },
				{ walletName: "opera" },
				{ walletName: "operaTouch" },
				{ walletName: "imToken", rpcUrl },
				{ walletName: "meetone" },
				{ walletName: "mykey", rpcUrl },
				{ walletName: "wallet.io", rpcUrl },
				{ walletName: "huobiwallet", rpcUrl },
				{ walletName: "alphawallet", rpcUrl },
				{ walletName: "hyperpay" },
				{ walletName: "atoken" },
				{ walletName: "liquality" },
				{ walletName: "frame" },
				{ walletName: "tokenpocket", rpcUrl },
				{ walletName: "authereum", disableNotifications: true },
				{ walletName: "ownbit" },
				{ walletName: "gnosis" },
				{ walletName: "dcent" },
				{ walletName: "bitpie" },
				{ walletName: "xdefi" },
				{ walletName: "binance" },
				{ walletName: "tp" },
				{ walletName: "tally" },
				{ walletName: "blankwallet" },
				{ walletName: "mathwallet" },
			],
		},
	});
}

export type OnboardContext = {
	address: string | undefined;
	balance: string | null;
	ens: Ens | null;
	network: number | undefined;
	onboard: OnboardAPI | null;
	provider: Web3Provider | null;
	signer: JsonRpcSigner | null;
	wallet: Wallet | null;
};

export const Context = createContext({
	address: undefined,
	balance: null,
	ens: null,
	network: undefined,
	onboard: null,
	provider: null,
	signer: null,
	wallet: null,
} as OnboardContext);

function useSigner({ provider }) {
	const [signer, setSigner] = useState(null);

	useEffect(() => {
		async function getSinger() {
			try {
				const newSigner = await provider.getSigner();

				setSigner(newSigner);
			} catch (error) {
				console.log("Error getting signer: ", error);
			}
		}

		if (provider === null) {
			setSigner(null);
		} else {
			getSinger();
		}
	}, [provider]);

	return { signer };
}

function useRememberedWallet({ onboard }) {
	useEffect(() => {
		const previouslySelectedWallet = window.localStorage.getItem("selectedWallet");

		if (previouslySelectedWallet && onboard) {
			onboard.walletSelect(previouslySelectedWallet);
		}
	}, [onboard]);
}

function useOnboard() {
	const [provider, setProvider] = useState(null);
	const [address, setAddress] = useState();
	const [ens, setEns] = useState(null);
	const [network, setNetwork] = useState();
	const [balance, setBalance] = useState(null);
	const [wallet, setWallet] = useState(null);
	const [onboard, setOnboard] = useState(null);
	const { isMobile } = useDeviceInfo();
	const [walletName, setWalletName] = useState(null);
	const mixpanel = useMixpanel();

	useEffect(() => {
		if (isMobile !== null && onboard === null) {
			const instance = initOnboard(
				{
					address: setAddress,
					balance: setBalance,
					ens: setEns,
					network: setNetwork,
					wallet: (newWallet) => {
						if (newWallet.provider) {
							setWallet(newWallet);

							setProvider(new ethers.providers.Web3Provider(newWallet.provider, "any"));

							window.localStorage.setItem("selectedWallet", newWallet.name);

							setWalletName(newWallet.name);
						} else {
							setProvider(null);
							setWallet(null);
						}
					},
				},
				{ isMobile },
			);

			setOnboard(instance);
		}
	}, [isMobile, onboard]);

	useEffect(() => {
		if (mixpanel && address && walletName) {
			mixpanel.track("Connected Wallet", {
				walletName,
				address,
			});
		}
	}, [address, walletName, mixpanel]);

	return { provider, wallet, address, onboard, ens, network, balance };
}

export function Provider({ children }: { children?: ReactNode }) {
	const { provider, wallet, address, onboard, ens, network, balance } = useOnboard();
	const { signer } = useSigner({ provider });

	useRememberedWallet({ onboard });

	const value = { address, balance, ens, network, onboard, provider, signer, wallet };

	return <Context.Provider value={value}>{children}</Context.Provider>;
}
