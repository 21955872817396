import Layout from "../components/Layout";
import { Provider as OnboardProvider } from "../contexts/Onboard";
import "../styles/globals.css";

const MyApp = ({ Component, pageProps }) => {
	return (
		<OnboardProvider>
			<Layout>
				<Component {...pageProps} />
			</Layout>
		</OnboardProvider>
	);
};

export default MyApp;
