// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.VERCEL_ENV !== "development") {
	Sentry.init({
		dsn: SENTRY_DSN || "https://e870053bc05342ef8d6a85573c68bab0@o1128592.ingest.sentry.io/6171473",
		// Adjust this value in production, or use tracesSampler for greater control
		tracesSampleRate: 1.0,
		// ...
		// Note: if you want to override the automatic release value, do not set a
		// `release` value here - use the environment variable `SENTRY_RELEASE`, so
		// that it will also get attached to your source maps
	});
}
