const NETWORK_ID = Number(process.env.WEB3_NETWORK_ID);

const ENVIRONMENT = process.env.VERCEL_ENV;

module.exports = {
	ENVIRONMENT,
	NETWORK_ID,
	RPC_URL: NETWORK_ID === 1 ? process.env.MAINNET_RPC : process.env.TESTNET_RPC,
	DAPP_ID: "6a90cf57-78d1-4a54-9202-ea3bd4b223dd",
	APP_URL: process.env.VERCEL_URL || "http:///localhost:3000/",
	EMAIL: process.env.EMAIL,
	APP_NAME: process.env.APP_NAME,
	CONTRACT_ADDRESS: NETWORK_ID === 1 ? process.env.MAINNET_MINT_CONTRACT_ADDRESS : process.env.TESTNET_MINT_CONTRACT_ADDRESS,
	ERC721ABI: {
		abi: [
			{
				anonymous: false,
				inputs: [
					{
						indexed: true,
						internalType: "address",
						name: "from",
						type: "address",
					},
					{
						indexed: true,
						internalType: "address",
						name: "to",
						type: "address",
					},
					{
						indexed: true,
						internalType: "uint256",
						name: "tokenId",
						type: "uint256",
					},
				],
				name: "Transfer",
				type: "event",
			},
		],
	},
	MINTING_ABI: [
		"function mint(uint256 numTokens) external payable",
		"function presaleMint(uint256 numTokens, bytes32[] calldata proof) external payable",
	],
	MINT_PRICE_IN_ETH: Number(process.env.MINT_PRICE_IN_ETH),
	MINT_QUANTITY_MAX: 20,
	MINT_QUANTITY_MIN: 1,
	// POSSIBLE TODO: add this as a env var?
	WALLET_GCLOUD_STORAGE_BUCKET: NETWORK_ID === 1 ? "seeds-nft-metadata" : "seeds-nft-metadata-test",

	// Styles
	PRIMARY_COLOR: "#00AF07",
	PRIMARY_COLOR_HSL: "122, 100%, 34%",
	ETHERSCAN_BASE_URL: NETWORK_ID === 1 ? "https://etherscan.io" : "https://ropsten.etherscan.io",

	// Analytics
	PIXEL_ID: process.env.PIXEL_ID,
	MIXPANEL_TOKEN: process.env.MIXPANEL_TOKEN,
};
